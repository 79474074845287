import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"

import SEO from "../components/Seo"
import Layout from "../components/Layout"
import ScrollToTop from "../components/blog/ScrollToTop"
import RecommendedPosts from "../components/blog/RecommendedPosts"

import "./style.css"
import * as S from "../components/blog/Post/styled"


function getImageURL(category, title, description) {
  return `https://metatag-banner-generator.vercel.app/api/metatag?category=${encodeURIComponent(
    category
  )}&title=${encodeURIComponent(title)}&description=${encodeURIComponent(
    description
  )}`
}

const BlogPost = ({ data, pageContext }) => {
  const [isLoaded, setIsLoaded] = useState(true)

  useEffect(() => {
    // Para dar trigger na animação somente após a página já ter carregado
    setTimeout(() => {
      setIsLoaded(false)
    }, 0)
  }, [])

  const post = data.markdownRemark

  const next = pageContext.nextPost
  const previous = pageContext.previousPost

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        absoluteImgUrl
        image={getImageURL(
          post.frontmatter.category,
          post.frontmatter.title,
          post.frontmatter.description
        )}
      />
      <S.PostHeader className={isLoaded ? "fade" : "faded"}>
        <S.PostDate>
          {post.frontmatter.date} | {post.timeToRead} min de leitura
        </S.PostDate>
        <S.PostCategory>{post.frontmatter.category}</S.PostCategory>
        <S.PostTitle>{post.frontmatter.title}</S.PostTitle>
        <S.PostDescription>{post.frontmatter.description}</S.PostDescription>
      </S.PostHeader>
      <S.MainContent className={isLoaded ? "fade slowed" : "faded"}>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </S.MainContent>
      <RecommendedPosts next={next} previous={previous} />
      <ScrollToTop />
    </Layout>
  )
}

export const query = graphql`
  query Post($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        category
        date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt-br")
        image
      }
      html
      timeToRead
    }
  }
`

export default BlogPost
